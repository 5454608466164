@import './../../../../theme/theme';

.mainNavContainer {
  height: 80px;
  width: 100%;
  background-color: $backgroundColor;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.12);
  z-index: 1030;
  position: fixed;
  top: 0;
}
.top_nav {
  justify-content: center;
  width: 100%;
  height: 80px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  max-width: 1660px;
  z-index: 1030;
  position: relative;
}

.brandLogos {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  left: 40px;
  height: 60px;
  .tenantLogo {
    height: 60px;
  }
  .primaryTenantLogo,
  .hidePrimaryTenantLogo {
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .logo_divider {
    height: 36px;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0rem 0.5rem;
  }
  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
}

.menuItems {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-left: -54px;
  .linkContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    > p {
      font-family: $primaryFontFamily;
      font-size: $fontSmall;
      font-weight: 700;
      margin: 5px;
      line-height: 1;
      color: $primaryFontColor;
    }
    > p.active_icon {
      color: $primaryColor;
    }
    .link_icon {
      width: 24px;
      height: 24px;
      margin: 5px;
      cursor: pointer;
    }
    > .link_icon > svg > path {
      fill: $primaryFontColor;
    }
    > .link_icon.active_icon > svg > path {
      fill: $primaryColor;
    }
    &:hover {
      cursor: pointer;
      color: $primaryColor;
      > p {
        color: $primaryColor;
      }
      > .link_icon > svg > path {
        fill: $primaryColor;
      }
    }
  }
}

.subMenu {
  position: relative;
  .subMenuItems {
    top: 78px;
    left: -5px;
    position: absolute;
    height: 330px;
    width: 300px;
    display: none;
    background-color: $backgroundColor;
    padding: 12px;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    color: $primaryColor;
    &.showSubMenu {
      display: flex;
    }
  }

  .link_icon {
    width: 24px;
    height: 24px;
    margin: 5px;
  }

  .menuText {
    padding-top: 5px;
    font-weight: 700;
    padding-left: 10px;
    display: flex;
    align-items: flex-start;
    font-family: inherit;
    font-size: 16px;
    color: $primaryFontColor;
  }
  & .menuRow {
    display: flex;
    margin-bottom: 10px;
    width: 100%;
    &:hover {
      .menuText {
        color: $primaryColor;
      }

      .link_icon svg g path {
        fill: $primaryColor;
      }
    }
  }
}

@include smTabletView {
  .top_nav {
    justify-content: flex-end;
    .brandLogos {
      left: 20px;
      height: 48px;
      .tenantLogo {
        height: 48px;
      }
    }
  }
}

@include mdTabletView {
  .top_nav {
    justify-content: center;
  }
}

@include xsPhoneView {
  .mainNavContainer {
    bottom: 0;
    top: auto;
  }
  .top_nav {
    display: flex !important;
    padding: 0;

    justify-content: space-between;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.12);
    .brandLogos {
      left: 20px;
      height: 48px;
      .hidePrimaryTenantLogo {
        display: none;
      }
      .tenantLogo {
        height: 48px;
      }
      .mobile {
        display: block;
      }
      .desktop {
        display: none;
      }
    }
  }
  .menuItems {
    margin-left: 0px;
    position: absolute;
    right: 20px;
    justify-content: right;
    align-items: center;
    .linkContainer {
      margin: 0px;
      > p {
        font-size: $fontXSmall;
      }
      .link_icon {
        margin: 5px;
      }
    }
  }
  .subMenuItems {
    top: -350px !important;
    left: -120px !important;
  }
}
